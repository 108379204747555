@font-face {
  /* font-family: 'Navigo';
  src: url('./assets/fonts/Navigo-Regular.eot') format('embedded-opentype'),
      url('./assets/fonts/Navigo-Regular.ttf') format('truetype'),
      url('./assets/fonts/Navigo-Regular.woff') format('woff'),
      url('./assets/fonts/Navigo-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: block; */
  font-family: 'Inter';
  src: url('../../assets/src/fonts/Inter-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
  'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

wcm-modal {
  z-index: 999999999999;
  position: relative;
}

::-webkit-scrollbar {
  width: 4px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: 'transparent';
  -webkit-border-radius: 12px;
  border-radius: 12px;
  padding-right: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 12px;
  border-radius: 12px;
  background: #7481a4;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

.ant-tooltip .ant-tooltip-inner {
  border: 1px solid #7481a4;
}

.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
  background-color: #4732D4 !important;
  width: 2px !important;
}

.ant-steps .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-tail::after {
  background-color: #4732D4 !important;
  width: 2px !important;
  opacity: 60%;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon >.ant-steps-icon {
  color: #fff !important;
}

.ant-steps-item-wait div.ant-steps-item-icon > span {
  color: #fff !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number]{
  -moz-appearance: textfield;
}

button:focus {
  outline: 'none !important';
}